export const getAscPageType = (pluginLocation: string): ASCPageType => {
  if (window.asc_datalayer?.page_type) {
    return window.asc_datalayer?.page_type;
  }
  const cleanLocation = pluginLocation?.replace("_mobile", "") ?? "";
  switch (cleanLocation) {
    case "srp":
      return "itemlist";
    case "vdp":
      return "item";
    case "home":
      return "home";
    case "landing":
    case "sell-us-your-car":
      return "trade";
  }
  return "unknown";
};
